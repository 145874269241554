<template>
  <div class="notice">
<!--      <div class="cont">-->
<!--        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" offset="10">-->
<!--          <template v-for="item in dataList">-->
<!--            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='村庄简介'" class="van-cell_1">-->
<!--              <div class="item">-->
<!--                <p>{{item.title}}</p>-->
<!--                <p style="font-size: 14px;opacity: .6">Introduction to the village</p>-->
<!--                <div class="line"></div>-->
<!--                <img src="@/assets/img/wisdom_1.png" alt="" />-->
<!--              </div>-->
<!--            </van-cell>-->
<!--          </template>-->
<!--          <template v-for="item in dataList">-->
<!--            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='特色品牌'" class="van-cell_1 van-cell_2">-->
<!--              <div class="item">-->
<!--                <p>{{item.title}}</p>-->
<!--                <p style="font-size: 14px;opacity: .6">Featured brands</p>-->
<!--                <div class="line"></div>-->
<!--                <img src="@/assets/img/wisdom_2.png" alt="" />-->
<!--              </div>-->
<!--            </van-cell>-->
<!--          </template>-->
<!--          <template v-for="item in dataList">-->
<!--            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='政策宣传'" class="van-cell_1 van-cell_3">-->
<!--              <div class="item">-->
<!--                <p>{{item.title}}</p>-->
<!--                <p style="font-size: 14px;opacity: .6">Policy advocacy</p>-->
<!--                <div class="line"></div>-->
<!--                <img src="@/assets/img/policy.png" alt="" style="width: 80px;height: 90px;margin-right: 10px"/>-->
<!--              </div>-->
<!--            </van-cell>-->
<!--          </template>-->

<!--        </van-list>-->
<!--        <van-empty description="没有数据哦" v-if="dataList.length < 1"/>-->
<!--      </div>-->
        <div class="cont">
          <van-cell class="top" @click="getInfo('0')">
            <h3>居民问卷</h3>
            <p>Resident questionnaire</p>
            <div class="line"></div>
            <img src="@/assets/img/resident.png" alt="" />
          </van-cell>
<!--          <van-cell class="top middle" @click="getInfo('1')">-->
<!--            <h3>社工问卷</h3>-->
<!--            <p>Social worker questionnaire</p>-->
<!--            <div class="line"></div>-->
<!--            <img src="@/assets/img/social.png" alt="" />-->
<!--          </van-cell>-->
          <van-cell class="top bottom" @click="getInfo('2')">
            <h3>我的问卷</h3>
            <p>My questionnaire</p>
            <div class="line"></div>
            <img src="@/assets/img/mine.png" alt="" />
          </van-cell>
        </div>
  </div>
</template>

<script>
export default {
     data() {
        return {
        searchValue: '',
        loading: false,
        finished: false,
        limit: 10,
        page: 0,
        dataList: [],
        };
     },
    methods: {
        getInfo (type) {
          console.log(type)
            this.$router.push({path: '/tabulation',query: {type}})
        },
    },
    created () {
    }
}
</script>

<style lang="scss" scoped>
//.notice {
//  .cont {
//    padding: 20px;
//    background-color: #f5f5f5;
//    .van-cell_1 {
//      position: relative;
//      height: 240px;
//      border-radius: 10px;
//      background-image: linear-gradient(to left,#7969EE 55px, #9188FF);
//      margin-top: 15px;
//    }
//    .van-cell_2 {
//      background-image: linear-gradient(to left,#F94646 55px, #FF7B7B);
//    }
//    .van-cell_3 {
//      background-image: linear-gradient(to left,#FF872D 55px, #FFB278);
//    }
//    .item {
//      p {
//        white-space: nowrap;      /*超出的空白区域不换行*/
//        overflow: hidden;         /*超出隐藏*/
//        text-overflow: ellipsis;  /*文本超出显示省略号*/
//        width: 550px;
//        margin: 10px 0;
//        font-size: 34px;
//        color: #fff;
//      }
//      span {
//        color: #666;
//      }
//      .line {
//        width: 50px;
//        height: 8px;
//        margin-top: 60px;
//        border-radius: 4px;
//        background-color: #fff;
//      }
//      img {
//        position: absolute;
//        bottom: -15px;
//        right: -20px;
//        height: 90%;
//      }
//    }
//  }
//}
.notice {
    padding-top: 100px;
    .cont {
        //padding: 20px;
        background-color: #f5f5f5;
        .top {
            position: relative;
            height: 240px;
            margin-top: 15px;
            border-radius: 10px;
            background-image: linear-gradient(to left,#F94646 55px, #FF7B7B);
          h3 {
            font-size: 40px;
            font-weight: 400;
            font-family: PingFang-SC-Bold;
            color: #fff;
            margin: 10px;
          }
          p {
            color: #fff;
            opacity: 0.4;
            margin: 0;
          }
          .line {
            width: 50px;
            height: 8px;
            margin-top: 60px;
            border-radius: 4px;
            background-color: #fff;
          }
          img {
            position: absolute;
            bottom: -15px;
            right: -19px;
            width: 155px;
            height: 155px;
          }
        }
      .middle {
        background-image: linear-gradient(to left,#387FF5 55px, #6EA4FF);
      }
      .bottom {
        background-image: linear-gradient(to left,#FF872D 55px, #FFB278);
        img {
          width: 142px;
          height: 158px;
        }
      }
        .item {
            p {
                margin: 10px 0;
                font-size: 30px;
            }
            span {
                color: #666;
            }
        }
    }
}
</style>
